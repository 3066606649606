import { computed, Directive, Input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const cardVariants = cva(
  'rounded-lg border border-border bg-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-text shadow-sm',
  {
    variants: {},
    defaultVariants: {}
  }
);
export type CardVariants = VariantProps<typeof cardVariants>;

@Directive({
  selector: '[hlmCard]',
  standalone: true,
  host: {
    '[class]': '_computedClass()'
  }
})
export class HlmCardDirective {
  private readonly _userCls = signal<ClassValue>('');
  @Input()
  set class(userCls: ClassValue) {
    this._userCls.set(userCls);
  }

  protected _computedClass = computed(() => this._generateClass());
  private _generateClass() {
    return hlm(cardVariants(), this._userCls());
  }
}
