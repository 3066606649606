export const RISK_LEVELS: { [key: string]: string } = {
  L: 'Low',
  M: 'Medium',
  H: 'High'
};

// Define reverse mapping
export const REVERSE_RISK_LEVELS: { [key: string]: string } = {
  Low: 'L',
  Medium: 'M',
  High: 'H'
};
