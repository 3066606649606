import { Pipe, PipeTransform } from '@angular/core';
import { REVERSE_RISK_LEVELS, RISK_LEVELS } from '@dx-web/modules/shared/types';

/**
 * A pipe that transforms risk level codes into human-readable text.
 * It uses a predefined map of risk level codes to their corresponding text representations.
 * It can also transform human-readable text back into risk level codes if the reverse flag is set to true.
 */
@Pipe({
  name: 'riskLevel',
  standalone: true
})
export class RiskLevelPipe implements PipeTransform {
  /**
   * Transforms a given risk level code into its text representation or vice versa.
   *
   * @param value The risk level code or text to be transformed.
   * @param reverse If true, transforms text into risk level code. Defaults to false.
   * @returns The text representation of the risk level code, or the risk level code if reverse is true.
   */
  transform(value: string, reverse = false): string {
    if (reverse) {
      return REVERSE_RISK_LEVELS[value] || value;
    }
    return RISK_LEVELS[value] || value;
  }
}
