export enum CaseDecision {
  Reject = 'Reject the on-boarding process',
  Approve = 'Approve the on-boarding process',
  Hold = 'Hold the on-boarding process'
}

export enum CaseRiskLevel {
  L = 'Low',
  M = 'Medium',
  H = 'High',
  U = 'Unacceptable'
}
