import { computed, Directive, Input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const inputVariants = cva(
  'flex w-full rounded-md border text-text text-sm font-normal shadow-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-text-muted focus:border-dx-blue focus:ring-dx-blue disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-background dark:bg-body border-border dark:bg-neutral-950',
        gray: 'bg-gray-100 border-transparent dark:bg-neutral-950'
      },
      size: {
        default: 'h-10 py-2 px-3',
        xs: 'px-2 py-1 text-xs',
        sm: 'h-9 px-3',
        lg: 'h-11 px-4'
      },
      error: {
        auto: '[&.ng-invalid.ng-touched]:text-destructive [&.ng-invalid.ng-touched]:border-destructive [&.ng-invalid.ng-touched]:focus-visible:ring-destructive',
        true: 'text-error focus-visible:ring-error border-error focus:border-error focus:ring-error'
      },
      iconPosition: {
        default: '',
        left: 'pl-7',
        right: 'pr-7',
        both: 'pl-7 pr-7'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      error: 'auto',
      iconPosition: 'default'
    }
  }
);
type InputVariants = VariantProps<typeof inputVariants>;

@Directive({
  selector: '[hlmInput]',
  standalone: true,
  host: {
    '[class]': '_computedClass()'
  }
})
export class HlmInputDirective {
  private readonly _variant = signal<InputVariants['variant']>('default');
  @Input()
  set variant(value: InputVariants['variant']) {
    this._variant.set(value);
  }

  private readonly _size = signal<InputVariants['size']>('default');
  @Input()
  set size(value: InputVariants['size']) {
    this._size.set(value);
  }

  private readonly _error = signal<InputVariants['error']>('auto');
  @Input()
  set error(value: InputVariants['error']) {
    this._error.set(value);
  }

  private readonly _iconPosition =
    signal<InputVariants['iconPosition']>('default');
  @Input()
  set iconPosition(value: InputVariants['iconPosition']) {
    this._iconPosition.set(value);
  }

  private readonly _userCls = signal<ClassValue>('');
  @Input()
  set class(userCls: ClassValue) {
    this._userCls.set(userCls);
  }
  protected _computedClass = computed(() => this._generateClass());
  private _generateClass() {
    return hlm(
      inputVariants({
        variant: this._variant(),
        size: this._size(),
        error: this._error(),
        iconPosition: this._iconPosition()
      }),
      this._userCls()
    );
  }
}
