import { NgClass, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { AuthService } from '@dx-web/modules/shared/data-access';

/**
 * AvatarComponent is responsible for displaying user avatars.
 * It supports two sizes and can display a default avatar if the user's avatar hasn't loaded.
 * It utilizes Angular's common directives for conditional styling and displaying content.
 */
@Component({
  selector: 'dx-web-avatar',
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgClass, NgStyle],
  template: `
    <!-- Avatar container with conditional classes for size and styles for background image -->
    <div
      class="flex items-center justify-center rounded-full border border-border bg-border bg-cover font-semibold"
      [ngClass]="{
        'h-[34px] w-[34px] text-sm': size() === 'sm',
        'h-[38px] w-[38px] text-sm': size() === 'lg',
        'h-[120px] w-[120px] text-3xl': size() === 'xl'
      }"
      [ngStyle]="{
        'background-image': avatarLoaded ? 'url(' + avatar + ')' : 'none'
      }"
    >
      <!-- Display user initials if avatar hasn't loaded -->
      <!-- User initials are dynamically calculated based on the user's name -->
      <ng-container *ngIf="!avatarLoaded">
        {{ getUserInitials() }}
      </ng-container>
    </div>
    <!-- Hidden img tag for preloading the avatar and triggering load event -->
    <img style="display: none;" [src]="avatar" (load)="onImageLoad()" />
  `
})
export class AvatarComponent {
  public size = input<'sm' | 'lg' | 'xl'>('lg');
  protected authService = inject(AuthService);
  protected user = this.authService.userSignal();
  protected avatarLoaded = false;
  protected defaultAvatar = 'assets/img/default-avatar.png';

  /**
   * Retrieves the avatar URL from the user object. If the user does not have an avatar, a default avatar URL is returned.
   * @returns {string} The URL of the user's avatar or the default avatar.
   */
  get avatar(): string {
    return this.user?.profile_pic || this.defaultAvatar;
  }

  /**
   * Calculates and returns the user's initials.
   * If the user has a first and last name, it uses the first letter of each.
   * If only one name is present, it uses the first two letters of that name.
   * Returns an empty string if no name is available.
   */
  protected getUserInitials(): string {
    const names = this.user?.name.split(' ');
    return names && names.length > 1
      ? `${names[0][0]}${names[names.length - 1][0]}`.toUpperCase()
      : this.user?.name
        ? this.user.name.substring(0, 2).toUpperCase()
        : '';
  }

  /**
   * Event handler for the image load event.
   * Sets the avatarLoaded flag to true when the avatar image has loaded.
   */
  protected onImageLoad() {
    this.avatarLoaded = true;
  }
}
