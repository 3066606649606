import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that transforms case status codes into human-readable text.
 * It uses a predefined map of status codes to their corresponding text representations.
 */
@Pipe({
  name: 'caseStatus',
  standalone: true
})
export class CaseStatusPipe implements PipeTransform {
  /**
   * A private map of status codes to their corresponding text representations.
   */
  private status: Record<string, string> = {
    C: 'Closed',
    O: 'Open',
    OR: 'Open and Reported',
    CR: 'Closed and Reported',
    R: 'Reopened',
    RJ: 'Rejected',
    AP: 'Approved',
    H: 'On Hold',
    U: 'Unacceptable'
  };

  /**
   * Transforms a given status code into its text representation.
   *
   * @param value The status code to be transformed.
   * @param args Additional arguments, currently not used.
   * @returns The text representation of the status code, or an empty string if the code is not recognized.
   */
  transform(value: string, ...args: unknown[]): string {
    if (value) {
      return this.status[value] || '';
    }
    return '';
  }
}
