import { DOCUMENT } from '@angular/common';
import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';

/**
 * ThemeToggleComponent allows users to toggle between light and dark themes.
 * It uses localStorage to persist the user's theme preference across sessions.
 */
@Component({
  selector: 'dx-web-theme-toggle',
  standalone: true,
  imports: [HlmButtonDirective, HlmIconComponent],
  template: `
    <button
      hlmBtn
      type="button"
      size="icon"
      variant="ghost"
      (click)="toggleTheme()"
    >
      <hlm-icon
        class="h-4 w-4 flex-shrink-0"
        [name]="isLightTheme ? 'lucideSun' : 'lucideMoon'"
      />
    </button>
  `
})
export class ThemeToggleComponent implements OnInit {
  private renderer = inject(Renderer2);
  private document = inject(DOCUMENT);
  public isLightTheme = true;

  ngOnInit() {
    this.isLightTheme = this.getTheme();
  }

  toggleTheme() {
    this.isLightTheme = !this.isLightTheme;
    this.applyTheme();
  }

  private getTheme(): boolean {
    const lsTheme = localStorage.getItem('theme');
    return lsTheme !== 'dark';
  }

  private applyTheme() {
    const themeClass = this.isLightTheme ? 'light' : 'dark';
    this.renderer.setAttribute(this.document.body, 'class', themeClass);
    localStorage.setItem('theme', themeClass);
  }
}
