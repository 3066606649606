export enum PercentageCircleColour {
  // open = '#67daff',
  open = '#3B82F6',
  closed = '#1ee994',
  assigned = '#6b7280',
  // solved = '#80e27e',
  solved = '#14B8A6',
  // reported = '#fff350',
  // reported = '#fb923c',
  reported = '#A855F7',
  // overdue = '#9a67ea',
  overdue = '#EF4444',
  unassigned = '#ff6090'
}
