import { computed, Directive, Input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const cardTitleDescriptionVariants = cva(
  'text-xs font-medium text-text-muted',
  {
    variants: {},
    defaultVariants: {}
  }
);
export type CardTitleDescriptionVariants = VariantProps<
  typeof cardTitleDescriptionVariants
>;

@Directive({
  selector: '[hlmCardTitleDescription]',
  standalone: true,
  host: {
    '[class]': '_computedClass()'
  }
})
export class HlmCardTitleDescriptionDirective {
  private readonly _userCls = signal<ClassValue>('');
  @Input()
  set class(userCls: ClassValue) {
    this._userCls.set(userCls);
  }

  protected _computedClass = computed(() => this._generateClass());
  private _generateClass() {
    return hlm(cardTitleDescriptionVariants(), this._userCls());
  }
}
