import { NgFor } from '@angular/common';
import { Component, effect, inject, input, model } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { UtilsService } from '@dx-web/modules/shared/data-access';
import { Tab } from '@dx-web/modules/shared/types';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';

const tabClasses = {
  page: 'flex min-h-10 flex-wrap items-center justify-between gap-x-4 gap-y-2',
  grid: 'flex min-h-10 flex-wrap items-center justify-between gap-x-4 gap-y-2 relative mb-4 flex space-x-1 p-0 after:absolute after:inset-x-0 after:bottom-0 after:border-b-2 after:border-border',
  navDiv:
    '[&amp;::-webkit-scrollbar]:h-1 [&amp;::-webkit-scrollbar-thumb]:rounded-full [&amp;::-webkit-scrollbar-track]:bg-gray-100 [&amp;::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500 !-mb-2 -ml-1 flex flex-row overflow-x-auto overflow-y-hidden whitespace-nowrap pb-2 pl-1'
};

/**
 * Component responsible for rendering and managing navigation tabs.
 *
 * @input tabs - The list of tabs to be displayed, each containing a label and a route.
 * @model activeTab - A model representing the currently active tab.
 *
 * This component listens to route changes to update the active tab accordingly and uses a utility service for string formatting.
 */
@Component({
  selector: 'dx-web-tabs',
  standalone: true,
  imports: [RouterLink, NgFor, HlmButtonDirective],
  template: `
    <!-- Container for navigation tabs with responsive design and custom scrollbar styling -->
    <nav role="tablist" aria-label="tabs" [class]="tabClasses[this.variant()]">
      <!-- Navigation tabs with horizontal scrolling -->
      <div [class]="tabClasses.navDiv">
        @for (tab of tabs(); track tab.label) {
          <a
            hlmBtn
            class="text-sm"
            role="tab"
            size="sm"
            [attr.aria-selected]="tab?.label === activeTab()?.label"
            [attr.tabindex]="tab?.label === activeTab()?.label ? 0 : -1"
            [routerLink]="tab?.route"
            [variant]="getTabVarient(tab)"
          >
            {{ utilsService.formatString(tab?.label || '') }}</a
          >
        }
      </div>
    </nav>
  `
})
export class TabsComponent {
  public tabs = input.required<Tab[]>();
  public variant = input<'page' | 'grid'>('page');
  public activeTab = model<Tab>();
  protected utilsService = inject(UtilsService);
  private activatedRoute = inject(ActivatedRoute);
  protected routeTab = this.activatedRoute.snapshot.data['tab'];
  protected tabClasses = tabClasses;

  constructor() {
    effect(
      () => {
        // Effect hook to set the active tab based on the current route or default to the first tab.
        this.activeTab.set(
          this.tabs().find((tab) => tab.label === this.routeTab) ||
            this.tabs()[0]
        );
      },
      { allowSignalWrites: true }
    );
  }

  /**
   * Determines the variant of the tab based on whether it is the active tab.
   *
   * @param tab - The tab to determine the variant for.
   * @returns hlmBtn variant.
   */
  protected getTabVarient(tab: Tab): 'outline' | 'link' | 'tab' | 'activeTab' {
    if (this.variant() === 'grid') {
      return this.activeTab()?.label === tab.label ? 'activeTab' : 'tab';
    }
    return this.activeTab()?.label === tab.label ? 'outline' : 'link';
  }
}
