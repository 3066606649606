import { computed, Directive, Input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const cardHeaderVariants = cva('flex p-5', {
  variants: {
    direction: {
      row: 'flex-row items-center space-x-1.5 justify-between',
      column: 'flex-col space-y-1.5'
    }
  },
  defaultVariants: {
    direction: 'column'
  }
});
export type CardHeaderVariants = VariantProps<typeof cardHeaderVariants>;

@Directive({
  selector: '[hlmCardHeader]',
  standalone: true,
  host: {
    '[class]': '_computedClass()'
  }
})
export class HlmCardHeaderDirective {
  private readonly _userCls = signal<ClassValue>('');
  @Input()
  set class(userCls: ClassValue) {
    this._userCls.set(userCls);
  }

  private readonly _direction =
    signal<CardHeaderVariants['direction']>('column');
  @Input()
  set direction(direction: CardHeaderVariants['direction']) {
    this._direction.set(direction);
  }

  protected _computedClass = computed(() => this._generateClass());
  private _generateClass() {
    return hlm(
      cardHeaderVariants({ direction: this._direction() }),
      this._userCls()
    );
  }
}
