import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from '@dx-web/modules/shared/types';
import { catchError, Observable, throwError } from 'rxjs';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private httpClient = inject(HttpClient);
  private utilsService = inject(UtilsService);

  public getUsersList(): Observable<User[]> {
    return this.httpClient
      .get<User[]>(this.utilsService.buildApiUrl('user/list/'))
      .pipe(
        catchError((error) => {
          console.error('get users list error:', error);
          return throwError(() => new Error('get users list failed'));
        })
      );
  }
}
