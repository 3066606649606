import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GridKey, GridSettings } from '@dx-web/modules/shared/types';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State
} from '@progress/kendo-data-query';

/**
 * Service responsible for managing the state of Kendo Grids across the application and some utility methods.
 * It provides functionality to update the URL based on grid state, parse URL parameters
 * back into grid state, save/load grid settings to/from localStorage, and determine if a column is sortable.
 */
@Injectable({
  providedIn: 'root'
})
export class KendoGridService {
  // Injecting Router and ActivatedRoute to manage URL state
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  /**
   * Updates the browser URL with the current state of the grid.
   * @param gridState The current state of the grid including pagination, filter, etc.
   */
  public updateUrl(gridState: State): void {
    // Clean the filters in the gridState before using them
    if (gridState.filter) {
      gridState.filter =
        (this.cleanFilters(gridState.filter) as CompositeFilterDescriptor) ||
        undefined;
    }

    const queryParams = this.buildQueryParams(gridState);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  /**
   * Cleans the filters by recursively removing any filters that have an empty or null value.
   *
   * This method checks if the provided filter is a `CompositeFilterDescriptor` or a `FilterDescriptor`.
   * If it's a `CompositeFilterDescriptor`, it will recursively clean its child filters and remove any
   * that are null or empty. If it's a `FilterDescriptor`, it will check the filter's value and return
   * null if the value is empty or null, indicating that the filter should be removed.
   *
   * @param filter A `CompositeFilterDescriptor` or `FilterDescriptor` that needs to be cleaned.
   * @returns The cleaned `CompositeFilterDescriptor` or `FilterDescriptor`, or null if the filter should be removed.
   */
  private cleanFilters(
    filter: CompositeFilterDescriptor | FilterDescriptor
  ): CompositeFilterDescriptor | FilterDescriptor | null {
    if ('filters' in filter) {
      filter.filters = filter.filters
        .map((f) => this.cleanFilters(f))
        .filter((f) => f !== null) as Array<
        CompositeFilterDescriptor | FilterDescriptor
      >;
      return filter.filters.length ? filter : null;
    } else {
      return filter.value === '' || filter.value === null ? null : filter;
    }
  }

  /**
   * Constructs query parameters for grid state navigation.
   *
   * This function takes the current state of the grid and constructs an object representing
   * the query parameters needed for URL navigation. It calculates the current page based on
   * the `skip` and `take` properties, defaults the page size to 20 if not provided, and
   * serializes the `sort` and `filter` properties into string representations if they exist.
   *
   * @param gridState The current state of the grid, including pagination, sorting, and filtering.
   * @returns An object containing the page, pageSize, sort, and filter as query parameters.
   */
  private buildQueryParams(gridState: State): {
    page: number;
    pageSize: number;
    sort: string | null;
    filter: string | null;
  } {
    const page = gridState.skip
      ? gridState.skip / (gridState.take ?? 20) + 1
      : 1;
    const pageSize = gridState.take ?? 20;
    const sort = gridState.sort
      ? encodeURIComponent(JSON.stringify(gridState.sort))
      : null;
    const filter = gridState.filter
      ? encodeURIComponent(JSON.stringify(gridState.filter))
      : null;

    return { page, pageSize, sort, filter };
  }

  /**
   * Parses URL parameters back into a grid state object.
   * @param params The URL parameters to parse.
   * @returns The parsed grid state.
   */
  public parseUrlParams(params: Params): State {
    return {
      skip:
        (params['page'] ? parseInt(params['page'], 10) - 1 : 0) *
        (params['pageSize'] ? parseInt(params['pageSize'], 10) : 20),
      take: params['pageSize'] ? parseInt(params['pageSize'], 10) : 20,
      // Decode and parse the sort & filter state
      sort: params['sort']
        ? JSON.parse(decodeURIComponent(params['sort']))
        : null,
      filter: params['filter']
        ? JSON.parse(decodeURIComponent(params['filter']))
        : null
    };
  }

  /**
   * Saves grid settings to localStorage.
   * @param gridKey The key under which to store the settings.
   * @param gridConfig The grid settings to store.
   */
  public saveGridSettingsToLocalStorage(
    gridKey: GridKey,
    gridConfig: GridSettings
  ): void {
    const allSettings = JSON.parse(localStorage.getItem('grids') || '{}');
    allSettings[gridKey] = gridConfig;
    localStorage.setItem(
      'grids',
      JSON.stringify(allSettings, this.handleCircularReferencesInJSON())
    );
  }

  /**
   * Retrieves grid settings from localStorage.
   * @param gridKey The key under which the settings are stored.
   * @returns The grid settings if found, otherwise null.
   */
  public getGridSettingsFromLocalStorage(gridKey: GridKey): GridSettings {
    const allSettings = JSON.parse(localStorage.getItem('grids') || '{}');
    return allSettings[gridKey] || null;
  }

  /**
   * A JSON.stringify replacer function that handles circular references by omitting them.
   * @returns A replacer function for JSON.stringify.
   */
  private handleCircularReferencesInJSON() {
    const seen = new WeakSet();
    return (_: any, value: any) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  /**
   * Extracts and formats grid parameters from the provided state for API requests
   *
   * This method performs the following operations:
   * - Calculates the page number using the `skip` and `take` properties from the state.
   * - Constructs an ordering string by processing the `sort` array, prefixing fields with '-' for descending order.
   * - Compiles filter parameters into a query string by processing the `filter` property.
   *
   * @param state The state object encapsulating grid parameters such as paging (`skip` and `take`), sorting (`sort`), and filtering (`filter`).
   * @returns An object containing the `page`, `ordering`, and `filters` parameters formatted for API requests, facilitating the server-side processing of grid data.
   */
  private getGridParams(state: State): {
    page: number;
    ordering: string;
    filters: string;
  } {
    const defaultTake = 20;
    const take = state.take || defaultTake;
    const page = (state.skip || 0) / take + 1;

    const ordering =
      state.sort
        ?.map((s) => `${s.dir === 'desc' ? '-' : ''}${s.field}`)
        .join(',') || '';

    const filters = this.getFilters(state);

    return { page: Math.ceil(page), ordering, filters };
  }

  /**
   * Processes the filters from the grid state and compiles them into a query string.
   * This method recursively processes each filter, supporting both simple and composite filters.
   *
   * @param state The state object containing the filters to process.
   * @returns A string representing the compiled filters in query string format.
   */
  private getFilters(state: State): string {
    let filters = '';
    /**
     * Recursively processes each filter, adding it to the filters reference array.
     *
     * @param filter The current filter to process, which can be either a simple or composite filter.
     * @param filtersRef A reference to the array where the processed filters are accumulated.
     */
    const processFilters = (
      filter: FilterDescriptor | CompositeFilterDescriptor,
      filtersRef: string[]
    ) => {
      // Check if the filter is a simple filter by the absence of 'filters' property
      if (!('filters' in filter) || !filter.filters) {
        // If it's a simple filter with a 'field' property, add it to the filters reference
        if ('field' in filter) {
          // Check if value is an object with 'key' and 'value' properties
          if (
            typeof filter.value === 'object' &&
            'key' in filter.value &&
            'value' in filter.value
          ) {
            filtersRef.push(`${filter.value.key}=${filter.value.value}`);
          } else {
            filtersRef.push(`${filter.field}=${filter.value}`);
          }
        }
        return;
      }
      // If it's a composite filter, recursively process each nested filter
      filter.filters.forEach((f) => processFilters(f, filtersRef));
    };

    // Check if there are any filters to process and initiate the recursive processing if so
    if (state.filter && state.filter.filters.length) {
      const filtersArray: string[] = [];
      state.filter.filters.forEach((filter) =>
        processFilters(filter, filtersArray)
      );
      // Join all processed filters into a single string
      filters = filtersArray.join('&');
    }

    return filters;
  }

  /**
   * Constructs a URL query string from the given grid state parameters.
   *
   * @param state - The state of the grid including pagination, sorting, and filtering.
   * @returns A string that represents the grid parameters as a URL query string.
   */
  public getGridParamsUrl(state: State): string {
    const { page, ordering, filters } = this.getGridParams(state);
    let url = `page=${page}`;
    if (ordering) {
      url += `&ordering=${ordering}`;
    }
    if (filters) {
      url += `&${filters}`;
    }
    return url;
  }

  /**
   * Finds a `FilterDescriptor` by recursively searching through an array of filters.
   *
   * This function searches for a `FilterDescriptor` that matches the specified field name.
   * It checks each filter in the provided array: if the filter is a `FilterDescriptor` with
   * a matching field, it is returned; if the filter contains nested filters, the function
   * recursively searches within those filters.
   *
   * @param filters - An array of `CompositeFilterDescriptor` or `FilterDescriptor` objects.
   * @param field - The field name to search for within the filters.
   * @returns A `FilterDescriptor` matching the field, or undefined if no match is found.
   */
  public findFilterDescriptor = (
    filters: Array<CompositeFilterDescriptor | FilterDescriptor>,
    field: string
  ): FilterDescriptor | undefined => {
    for (const filter of filters) {
      if ('field' in filter && filter.field === field) {
        return filter;
      } else if ('filters' in filter) {
        const found = this.findFilterDescriptor(filter.filters, field);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  /**
   * @description Handles data state change events such as sorting, paging, and filtering.
   * @param state The new state of the data.
   */
  public dataStateChange(state: DataStateChangeEvent): void {
    this.updateUrl(state);
  }

  /**
   * @description Handles page change events to update the grid state and URL.
   * @param newPage The new page number.
   */
  public onPageChange(newPage: number, state: State): void {
    this.updateUrl({
      ...state,
      skip: (newPage - 1) * (state.take || 20)
    });
  }

  /**
   * Clears all filters applied to the grid and updates the URL to reflect the change.
   * @param state The current state of the grid.
   */
  public clearFilters(state: State): void {
    state.filter = undefined;
    this.updateUrl(state);
  }
}
