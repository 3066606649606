import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { BadgeStyle } from '@dx-web/modules/shared/types';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'dx-web-badge',
  standalone: true,
  imports: [NgClass, TooltipModule],
  template: `
    <span
      class="select-none inline-flex justify-center min-w-6 items-center rounded-lg px-3 py-1 text-xs font-semibold {{
        additionalClasses()
      }}"
      [ngClass]="styleClasses"
      [pTooltip]="tooltip()"
    >
      {{ text() }}
    </span>
  `
})
export class BadgeComponent {
  public text = input.required<string | number>();
  public tooltip = input.required<string>();
  public style = input.required<BadgeStyle>();
  public additionalClasses = input('');

  get styleClasses() {
    switch (this.style()) {
      case 'green':
        return 'bg-teal-100 text-teal-800 dark:bg-teal-800/30 dark:text-teal-400';

      case 'green-outline':
        return 'bg-background border border-border text-teal-700 dark:text-teal-500';

      case 'blue':
        return 'bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-300';

      case 'blue-outline':
        return 'bg-background border border-border text-blue-700 dark:text-blue-300';

      case 'red':
        return 'bg-red-100 text-red-800 dark:bg-red-800/30 dark:text-red-300';

      case 'red-outline':
        return 'bg-background border border-border text-red-800 dark:text-red-300';

      case 'yellow':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800/30 dark:text-yellow-500';

      case 'yellow-outline':
        return 'bg-background border border-border text-yellow-700 dark:text-yellow-400';

      case 'orange':
        return 'bg-orange-100 text-orange-800 dark:bg-orange-800/30 dark:text-orange-400';

      case 'orange-outline':
        return 'bg-background border border-border text-orange-700 dark:text-orange-400';

      case 'white':
        return 'bg-background text-neutral-800 dark:text-neutral-500 border border-border';

      case 'default':
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white';
    }
  }
}
