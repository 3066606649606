import { Location } from '@angular/common';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@dx-web/modules/shared/environments';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * A service providing utility functions for various operations across the application.
 * This includes building API URLs, accessing nested properties of objects, and merging CSS class names.
 */
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private router = inject(Router);
  protected location = inject(Location);
  public routeHistory: WritableSignal<string[]> = signal<string[]>([]);

  /**
   * Navigates to the previous URL in the route history if available, otherwise navigates to the root URL.
   * This method modifies the route history by removing the last entry before deciding the navigation strategy.
   */
  public navigateToPreviousUrl(): void {
    const routeHistoryValue = this.routeHistory();
    routeHistoryValue.pop();
    if (routeHistoryValue.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  /**
   * A writable signal for managing the header title state across the application.
   */
  public headerTitleSignal: WritableSignal<string> = signal<string>('');

  /**
   * Constructs a full API URL by appending the given endpoint to the base API URL defined in the environment settings.
   * @param endpoint The specific API endpoint to be appended to the base URL.
   * @returns The full URL to the API endpoint.
   */
  public buildApiUrl(endpoint: string): string {
    return `${environment.apiUrl}/${endpoint}`;
  }

  /**
   * Retrieves a nested property value from an object using a dot-separated path.
   * This function is generic and can be used to access nested properties on any type of object.
   * @param obj The object from which to retrieve the property value.
   * @param path A dot-separated string specifying the path to the nested property.
   * @returns The value of the nested property, or undefined if any part of the path is not found.
   */
  public getNestedPropertyValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  /**
   * Merges multiple class names into a single string, handling conditional classes and deduplication.
   * This is particularly useful for dynamically setting CSS classes in Angular templates.
   * @param inputs An array of class values, which can be strings, objects, or arrays.
   * @returns A single string with merged class names.
   */
  public classMerge(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
  }

  /**
   * Formats a string in the format of `word-word` by capitalizing the first letter of each word.
   *
   * @param string - The string to format.
   * @returns The formatted string.
   */
  public formatString(string: string): string {
    return string
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  /**
   * Generates an array filled with zeros, primarily used for loading states.
   * The length of the array can be specified, defaulting to 2 if not provided.
   *
   * @param length The desired length of the loading array.
   * @returns An array of specified length filled with zeros.
   */
  public getLoadingArray(length = 2): number[] {
    return Array(length).fill(0);
  }
}
