import { computed, Directive, Input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const cardDescriptionVariants = cva('text-sm text-text-muted', {
  variants: {},
  defaultVariants: {}
});
export type CardDescriptionVariants = VariantProps<
  typeof cardDescriptionVariants
>;

@Directive({
  selector: '[hlmCardDescription]',
  standalone: true,
  host: {
    '[class]': '_computedClass()'
  }
})
export class HlmCardDescriptionDirective {
  private readonly _userCls = signal<ClassValue>('');
  @Input()
  set class(userCls: ClassValue) {
    this._userCls.set(userCls);
  }

  protected _computedClass = computed(() => this._generateClass());
  private _generateClass() {
    return hlm(cardDescriptionVariants(), this._userCls());
  }
}
