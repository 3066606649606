import { computed, Directive, Input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-lg text-sm font-semibold transition duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background gap-x-2',
  {
    variants: {
      variant: {
        default: 'bg-blue-600 text-white hover:bg-blue-700',
        'default-solf': 'bg-blue-100 text-blue-700 hover:bg-blue-200',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-border bg-background !text-text shadow-sm hover:shadow-md hover:border-gray-300 dark:bg-neutral-950 dark:hover:border-neutral-500 hover:no-underline',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost:
          'text-text hover:bg-accent dark:hover:bg-neutral-950 hover:text-accent-foreground',
        link: 'underline-offset-4 hover:underline !text-text',
        input: 'text-text-muted hover:text-text',
        tab: 'relative mb-2 inline-flex cursor-pointer items-center gap-x-2 rounded-lg px-2.5 py-1.5 text-sm text-text-muted transition after:pointer-events-none after:absolute after:inset-x-0 after:-bottom-2 after:z-10 after:h-0.5 hover:bg-border hover:!text-text hover:no-underline focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-text-muted',
        activeTab:
          'relative mb-2 inline-flex cursor-pointer items-center gap-x-2 rounded-lg px-2.5 py-1.5 text-sm text-text transition after:pointer-events-none after:absolute after:inset-x-0 after:-bottom-2 after:z-10 after:h-0.5 hover:bg-border hover:!text-text hover:no-underline focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-text-muted after:bg-border-foreground'
      },
      size: {
        default: 'h-10 py-2 px-4',
        xs: 'h-7 px-2 text-xs',
        sm: 'h-9 px-3 text-xs',
        lg: 'h-11 px-8',
        icon: 'h-[38px] w-[38px]',
        'icon-input': 'w-6 h-full'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);
type ButtonVariants = VariantProps<typeof buttonVariants>;

@Directive({
  selector: '[hlmBtn]',
  standalone: true,
  host: {
    '[class]': '_computedClass()'
  }
})
export class HlmButtonDirective {
  private readonly _userCls = signal<ClassValue>('');
  @Input()
  set class(userCls: ClassValue) {
    this._userCls.set(userCls);
  }

  private readonly _variant = signal<ButtonVariants['variant']>('default');
  @Input()
  set variant(variant: ButtonVariants['variant']) {
    this._variant.set(variant);
  }

  private readonly _size = signal<ButtonVariants['size']>('default');
  @Input()
  set size(size: ButtonVariants['size']) {
    this._size.set(size);
  }

  protected _computedClass = computed(() => this._generateClass());
  private _generateClass() {
    return hlm(
      buttonVariants({ variant: this._variant(), size: this._size() }),
      this._userCls()
    );
  }
}
