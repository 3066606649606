import { NgIf } from '@angular/common';
import { Component, effect, input } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';

/**
 * A component to display the sort icon in a grid column header.
 * It shows different icons based on the current sort state of the column.
 * This component is standalone and imports only the necessary dependencies.
 */
@Component({
  selector: 'dx-web-grid-column-sort-icon',
  standalone: true,
  imports: [NgIf, HlmIconComponent],
  template: `
    @if (!sortState) {
      <hlm-icon class="h-3.5 w-3.5 flex-shrink-0" name="lucideChevronsUpDown" />
    } @else if (sortState === 'ascending') {
      <hlm-icon
        class="h-3.5 w-3.5 flex-shrink-0 text-blue-600"
        name="lucideChevronUp"
      />
    } @else if (sortState === 'descending') {
      <hlm-icon
        class="h-3.5 w-3.5 flex-shrink-0 text-blue-600"
        name="lucideChevronDown"
      />
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        margin-left: 4px;
      }
    `
  ]
})
export class GridColumnSortIconComponent {
  columnField = input.required<string | undefined>();
  colSortState = input.required<SortDescriptor[] | undefined>();
  sortState: null | 'ascending' | 'descending' = null;

  /**
   * Constructs the GridColumnSortIconComponent and initializes an effect to determine the sort state.
   *
   * This effect sets the `sortState` to null initially, then checks if there is a sort state value available.
   * If a sort state value exists and contains at least one item, it attempts to find a sort descriptor
   * that matches the current column field. If such a descriptor is found and it has a direction (`dir`),
   * the `sortState` is updated to either 'ascending' or 'descending' based on the direction value.
   */
  constructor() {
    effect(() => {
      this.sortState = null;
      const colSortStateValue = this.colSortState();
      if (colSortStateValue) {
        if (colSortStateValue?.length > 0) {
          const sortDescriptor = colSortStateValue.find(
            (sd) => sd.field === this.columnField()
          );
          if (sortDescriptor?.dir) {
            this.sortState =
              sortDescriptor.dir === 'asc' ? 'ascending' : 'descending';
          }
        }
      }
    });
  }
}
