import { NgIf } from '@angular/common';
import { Component, effect, input } from '@angular/core';
import { CamelCasePipe } from '@dx-web/modules/shared/pipes';
import {
  BadgeStyle,
  FeRiskScore,
  RiskLevelColour
} from '@dx-web/modules/shared/types';
import { BadgeComponent } from './badge.component';

// eslint-disable-next-line @nx/enforce-module-boundaries

@Component({
  selector: 'dx-web-risk-indicator-bar',
  standalone: true,
  imports: [NgIf, BadgeComponent, CamelCasePipe],
  template: `
    @if (position() === 'column') {
      <div>
        <div class="mb-3 flex items-center justify-between">
          <p class="text-xs font-medium text-text">
            {{
              risk().type.toLowerCase() === 'pep'
                ? (risk().type | camelCase: true)
                : (risk().type | camelCase)
            }}
          </p>
        </div>
        <div class="relative">
          @if (risk().type !== 'loading') {
            <div
              class="absolute -top-[10px]"
              [style.margin-inline-start]="marginStart"
            >
              <dx-web-badge
                additionalClasses="w-6 h-6 min-w-6 !p-0"
                [style]="riskDetail.badgeStyle || 'white'"
                [text]="risk().score || ''"
                [tooltip]="tooltip"
              />
            </div>
          }
          <div
            class="flex h-2 w-full overflow-hidden rounded-full bg-border"
            role="progressbar"
            aria-valuemax="5"
            aria-valuemin="0"
            [attr.aria-valuenow]="risk().score"
          >
            <div
              class="flex flex-col justify-center overflow-hidden whitespace-nowrap rounded-full {{
                riskDetail.barColour
              }}"
              style="width: {{ ((risk().score - 1) / 4) * 100 }}%"
            ></div>
          </div>
        </div>
      </div>
    } @else if (position() === 'row') {
      <!-- side by side -->
      <div class="grid grid-cols-[168px,1fr] items-center gap-4">
        <!-- Div for Text -->
        <div>
          <p class="text-xs font-medium text-text">
            {{
              risk().type.toLowerCase() === 'pep'
                ? (risk().type | camelCase: true)
                : (risk().type | camelCase)
            }}
          </p>
        </div>

        <!-- Div for Bar and Badge -->
        <div class="relative w-full">
          @if (risk().type !== 'loading') {
            <div
              class="absolute -top-[10px]"
              [style.margin-inline-start]="marginStart"
            >
              <dx-web-badge
                additionalClasses="w-6 h-6 min-w-6 !p-0"
                [style]="riskDetail.badgeStyle || 'white'"
                [text]="risk().score || ''"
                [tooltip]="tooltip"
              />
            </div>
          }
          <div
            class="flex h-2 w-full overflow-hidden rounded-full bg-border"
            role="progressbar"
            aria-valuemax="5"
            aria-valuemin="0"
            [attr.aria-valuenow]="risk().score"
          >
            <div
              class="flex flex-col justify-center overflow-hidden whitespace-nowrap rounded-full {{
                riskDetail.barColour
              }}"
              style="width: {{ ((risk().score - 1) / 4) * 100 }}%;"
            ></div>
          </div>
        </div>
      </div>
    }
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ]
})
export class RiskIndicatorBarComponent {
  risk = input.required<FeRiskScore>();
  position = input<'row' | 'column'>('column');
  riskDetail: { level: string; badgeStyle: BadgeStyle; barColour: string } = {
    level: 'Low',
    badgeStyle: 'white',
    barColour: ''
  };

  constructor() {
    effect(() => {
      if (this.risk().score < 3) {
        this.riskDetail = {
          level: 'Low',
          badgeStyle: 'green-outline',
          barColour: RiskLevelColour.low
        };
      } else if (this.risk().score < 4) {
        this.riskDetail = {
          level: 'Medium',
          badgeStyle: 'yellow-outline',
          barColour: RiskLevelColour.medium
        };
      } else {
        this.riskDetail = {
          level: 'High',
          badgeStyle: 'red-outline',
          barColour: RiskLevelColour.high
        };
      }
    });
  }

  protected get tooltip(): string {
    const camelCasePipe = new CamelCasePipe();
    let tooltip =
      this.risk().type.toLowerCase() === 'pep'
        ? camelCasePipe.transform(this.risk().type, true)
        : camelCasePipe.transform(this.risk().type);

    tooltip += `: ${this.riskDetail.level} risk`;

    return tooltip;
  }

  protected get marginStart(): string {
    if (this.risk().score === 1) {
      return '0px';
    } else {
      const percentage = ((this.risk().score - 1) / 4) * 100;
      return `calc(${percentage}% - 1.25rem)`;
    }
  }
}
