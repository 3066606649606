import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that transforms string camelCase strings into readable string.
 * It can also optionally convert the entire string to uppercase.
 */
@Pipe({
  name: 'camelCase',
  standalone: true
})
export class CamelCasePipe implements PipeTransform {
  /**
   * Transforms the input string into readable format.
   *
   * @param value The camelCase string value to be transformed.
   * @param makeUpperCase A boolean flag indicating whether to convert the entire string to uppercase.
   * @returns The transformed string in readable format, optionally in uppercase.
   */
  transform(value: string, makeUpperCase = false): string {
    if (!value) {
      return value;
    }

    let result = value
      .replace(/([A-Z])/g, ' $1') // Insert a space before all caps.
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter.
      .trim(); // Trim spaces at the beginning.

    // Make 'of' lowercase.
    result = result.replace(/\bOf\b/g, 'of');

    // Convert entire string to uppercase if argument is true.
    if (makeUpperCase) {
      result = result.toUpperCase();
    }

    return result;
  }
}
