import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that transforms snake_case strings into a human-readable format.
 * Each underscore is replaced with a space, and each word is capitalized.
 */
@Pipe({
  name: 'snakeCase',
  standalone: true
})
export class SnakeCasePipe implements PipeTransform {
  /**
   * Transforms a snake_case string into a human-readable format.
   *
   * @param value The snake_case string to be transformed.
   * @returns The transformed string with spaces instead of underscores and each word capitalized.
   */
  transform(value: string): string {
    if (!value) return value;
    return value
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}
