import { NgClass, NgIf } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';

/**
 * FormInputComponent is a reusable Angular component that encapsulates the functionality of an input field within a form.
 * It supports various input types (text, password, email), custom placeholders, custom error messages, and form control integration.
 * It is designed to be used in reactive forms and utilizes Angular's FormControl to provide its functionality.
 *
 * @Input properties:
 * - label: Optional label for the input field.
 * - labelSrOnly: Determines if the label should be visually hidden.
 * - inputType: Type of the input field (text, password, email, number).
 * - size: Size of the input field (default, sm, lg).
 * - placeholder: Placeholder text for the input field.
 * - control: FormControl instance associated with the input.
 * - controlName: Name of the form control, used for setting 'id' and 'formControlName'.
 * - errorMessage: Error message to display when the input is invalid.
 * - formSubmitted: Flag indicating if the form has been submitted, used for error styling.
 * - autocomplete: Autocomplete attribute value for the input field.
 * - forceDisplayError: Forces the display of the error message regardless of form submission state.
 */
@Component({
  selector: 'dx-web-form-input',
  standalone: true,
  imports: [NgIf, NgClass, ReactiveFormsModule, HlmInputDirective],
  template: ` <div>
    @if (label()) {
      <div class="mb-2 flex items-center justify-between">
        <label
          class="block text-sm leading-none text-text"
          [for]="controlName"
          >{{ label() }}</label
        >
        <!-- Slot for content projection, e.g., a 'forgot password' link -->
        <ng-content></ng-content>
      </div>
    }
    <input
      hlmInput
      variant="default"
      [attr.aria-describedby]="isInvalid ? controlName() + '-error' : null"
      [attr.autocomplete]="autocomplete()"
      [attr.formControlName]="controlName()"
      [error]="isInvalid"
      [formControl]="control()"
      [id]="controlName()"
      [ngClass]="{
        'pr-10 after:absolute after:right-0 after:contents': showRightIcon()
      }"
      [placeholder]="placeholder()"
      [size]="size()"
      [type]="inputType()"
    />
    <!-- @if (showRightIcon()) {
      <div
        class="absolute right-10 top-[2px] h-9 w-10 bg-gradient-to-r from-white/0 to-white"
      ></div>
    } -->
    @if (errorMessage() && isInvalid) {
      <p class="mt-2 text-xs text-error" [id]="controlName() + '-error'">
        {{ errorMessage() }}
      </p>
    }
  </div>`,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ]
})
export class FormInputComponent {
  label = input<string>();
  inputType = input<'text' | 'password' | 'email' | 'number'>('text');
  size = input<'default' | 'sm' | 'lg'>('default');
  placeholder = input<string>('');
  control = input.required<FormControl>();
  controlName = input.required<string>();
  errorMessage = input<string>('');
  formSubmitted = input<boolean>(false);
  autocomplete = input<string>();
  forceDisplayError = input<boolean>();
  showRightIcon = input<boolean>();

  /**
   * Determines if the input field is invalid and the form has been submitted.
   * This is used to control the visibility of the error message and apply error styling.
   */
  get isInvalid(): boolean {
    if (this.forceDisplayError()) {
      return true;
    }
    return this.control().invalid && this.formSubmitted();
  }
}
