import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const authLayoutGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const authToken = authService.getAuthToken();
  const isLoggedIn = authService.isLoggedIn();

  if (!authToken || !isLoggedIn) {
    return true;
  }

  const returnUrlParams =
    router.routerState.snapshot.url.length > 1
      ? { queryParams: { returnUrl: router.routerState.snapshot.url } }
      : {};
  router.navigate([], returnUrlParams);
  return false;
};
