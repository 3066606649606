import {
  HttpContextToken,
  HttpErrorResponse,
  HttpInterceptorFn
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { AuthService } from './auth.service';

// Defining a context token to optionally bypass this interceptor
export const BYPASS_AUTH_TOKEN = new HttpContextToken<boolean>(() => false);

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  // Check if the current request should bypass this interceptor
  if (request.context.get(BYPASS_AUTH_TOKEN)) {
    return next(request);
  }

  const router = inject(Router);
  const authService = inject(AuthService);
  const authToken = authService.getAuthToken();
  const isLoggedIn = authService.isLoggedIn();

  if (!authToken || !isLoggedIn) {
    authService.clearSessionAndNavigateToAuth(router);
    return next(request);
  }

  const authReq = request.clone({
    headers: request.headers.set('Authorization', `Bearer ${authToken}`)
  });

  return next(authReq).pipe(
    catchError(handleHttpError(authService, router, isLoggedIn))
  );
};

function handleHttpError(
  authService: AuthService,
  router: Router,
  isLoggedIn: boolean
) {
  return (error: HttpErrorResponse) => {
    if (error.status === 401 || !isLoggedIn) {
      authService.clearSessionAndNavigateToAuth(router);
    }
    return throwError(() => error);
  };
}
