import { HttpClient, HttpContext } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  AuthUser,
  LoginCredentials,
  ResetPassword
} from '@dx-web/modules/shared/types';
import { finalize, first, Observable, tap } from 'rxjs';
import { BYPASS_AUTH_TOKEN } from './auth.interceptor';
import { UtilsService } from './utils.service';

/**
 * AuthService is responsible for managing authentication state and user information.
 * It interacts with the backend for login and logout operations and maintains the user's
 * session information in local storage and a reactive signal.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private httpClient = inject(HttpClient);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private jwtHelper = inject(JwtHelperService);
  private utilsService = inject(UtilsService);

  // A reactive signal to store and observe changes in the current user's state.
  public userSignal: WritableSignal<AuthUser | null> = signal<AuthUser | null>(
    null
  );

  /**
   * Initiates the login process by submitting the provided login credentials to the server.
   * Upon successful authentication, it stores the user's session data and redirects to either a specified return URL or the home page.
   * @param loginCredentials The credentials used for logging in, including username and password.
   * @returns An Observable emitting the authenticated user's data upon successful login.
   */
  public login(loginCredentials: LoginCredentials): Observable<AuthUser> {
    return this.httpClient
      .post<AuthUser>(
        this.utilsService.buildApiUrl('user/obtain_token/'),
        loginCredentials,
        {
          context: new HttpContext().set(BYPASS_AUTH_TOKEN, true)
        }
      )
      .pipe(
        tap((res) => {
          this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
            const returnUrl = params['returnUrl'] || '/';
            this.setUser({ ...res, email: loginCredentials.email });

            // TODO: talk to backend devs to make this unecessary
            this.getLoggedInUserDetail().subscribe((userResult) => {
              this.setUser({
                ...this.userSignal(),
                ...userResult
              });
              this.router.navigateByUrl(decodeURIComponent(returnUrl));
            });
          });
        })
      );
  }

  private getLoggedInUserDetail(): Observable<AuthUser> {
    return this.httpClient.get<AuthUser>(
      this.utilsService.buildApiUrl('user/user_detail/')
    );
  }

  /**
   * Updates the user's session information in local storage and the userSignal.
   * @param user The user object to set in the session.
   */
  public setUser(user: AuthUser) {
    localStorage.setItem('user', JSON.stringify(user));
    this.userSignal.set(user);
  }

  /**
   * Retrieves the current user from the userSignal or local storage if not present in the signal.
   * @returns The current user or null if not logged in.
   */
  private getUser(): AuthUser | null {
    let user = this.userSignal();
    if (!user) {
      const authUserLS = localStorage.getItem('user');
      user = authUserLS ? JSON.parse(authUserLS) : null;
      if (user) {
        this.userSignal.set(user);
      }
    }
    return user;
  }

  /**
   * Retrieves the current user's authentication token.
   * @returns The authentication token or null if not logged in.
   */
  public getAuthToken(): string | null {
    const authUserLS = localStorage.getItem('user');
    if (!authUserLS) {
      return null;
    }
    return this.getUser()?.token || null;
  }

  /**
   * Checks if the user is currently logged in based on the presence and validity of the auth token.
   * @returns true if the user is logged in, false otherwise.
   */
  public isLoggedIn(): boolean {
    const token = this.getAuthToken();
    return token ? !this.jwtHelper.isTokenExpired(token) : false;
  }

  /**
   * Performs the logout operation by clearing the user's session information and navigating to the auth page.
   * @returns An Observable with the logout response from the backend.
   */
  logout(): Observable<{ message: string }> {
    return this.httpClient
      .post<{
        message: string;
      }>(this.utilsService.buildApiUrl('user/logout/'), {})
      .pipe(
        finalize(() => {
          this.clearSessionAndNavigateToAuth(this.router);
        })
      );
  }

  /**
   * Initiates a password reset process by sending the user's email to the backend.
   * On success, the backend sends a password reset link to the provided email.
   * @param email The user's email address.
   * @returns An Observable with a message indicating the result of the operation.
   */
  public forgotPassword(email: {
    email: string;
  }): Observable<{ message: string }> {
    return this.httpClient.post<{ message: string }>(
      this.utilsService.buildApiUrl('user/forgot_password/'),
      email,
      {
        context: new HttpContext().set(BYPASS_AUTH_TOKEN, true)
      }
    );
  }

  /**
   * Resets the users password by submitting a request with the user's ID, token, and new password.
   * @param resetPassword An object containing the user's ID, token, and new password.
   * @returns An Observable with a message indicating the result of the operation.
   */
  public resetPassword(
    resetPassword: ResetPassword
  ): Observable<{ message?: string; error?: string }> {
    return this.httpClient.post<{ message?: string; error?: string }>(
      this.utilsService.buildApiUrl('user/reset_password/'),
      resetPassword,
      {
        context: new HttpContext().set(BYPASS_AUTH_TOKEN, true)
      }
    );
  }

  /**
   * Clears the user's session information from local storage and the userSignal, then navigates to the auth page.
   * @param router The Router instance used for navigation.
   */
  public clearSessionAndNavigateToAuth(router: Router) {
    this.userSignal.set(null);
    localStorage.removeItem('user');
    const returnUrl = router.routerState.snapshot.url;
    const returnUrlParams = returnUrl
      ? { queryParams: { returnUrl: returnUrl } }
      : {};
    return router.navigate(['login'], returnUrlParams);
  }

  public hasPermissions(permissions: string[]): boolean {
    const user = this.getUser();

    if (user && permissions.length > 0) {
      return permissions.every((permission) =>
        user.permissions.includes(permission)
      );
    }

    return false;
  }
}
