export enum GridKey {
  HomeAssignedCustomerCases = 'home.assigned.customer-cases',
  HomeAssignedEllipticCases = 'home.assigned.elliptic-cases',
  CasesOpenCustomerCases = 'cases.open.customer-cases',
  CasesOpenEllipticCases = 'cases.open.elliptic-cases',
  CasesClosedCustomerCases = 'cases.closed.customer-cases',
  CasesClosedEllipticCases = 'cases.closed.elliptic-cases',
  CustomersCustomers = 'customers.customers',
  CustomerCurrentCases = 'customer.current-cases',
  CustomerFormerCases = 'customer.former-cases',
  CustomerTransactions = 'customer.transactions',
  RetailRisksCountryOfResidence = 'retail-risks.country-of-residence',
  RetailRisksSourceOfFunds = 'retail-risks.source-of-funds',
  RetailRisksCountryOfCitizenship = 'retail-risks.country-of-citizenship',
  RetailRisksCountryOfBirth = 'retail-risks.country-of-birth'
}
