import { NgClass, NgIf } from '@angular/common';
import { Component, effect, input } from '@angular/core';
import { BadgeComponent } from '@dx-web/modules/shared/ui';

interface RiskScoreDetail {
  id: number;
  tooltip: string;
  style: 'default' | 'green' | 'blue' | 'red' | 'yellow';
}

@Component({
  selector: 'dx-web-risk-score-badge',
  standalone: true,
  imports: [NgIf, NgClass, BadgeComponent],
  template: `
    @if (riskDetail) {
      <dx-web-badge
        additionalClasses="min-w-9"
        [style]="riskDetail.style || 'default'"
        [text]="riskDetail.tooltip.charAt(0) || ''"
        [tooltip]="riskDetail.tooltip || ''"
      />
    }
  `
})
export class RiskScoreBadgeComponent {
  riskScore = input.required<number>();
  riskScoreDetail: RiskScoreDetail[] = [
    {
      id: 1,
      tooltip: 'No Match',
      style: 'green'
    },
    {
      id: 3,
      tooltip: 'Potential Match',
      style: 'yellow'
    },
    {
      id: 5,
      tooltip: 'Exact Match',
      style: 'red'
    }
  ];
  riskDetail: RiskScoreDetail | undefined;

  constructor() {
    effect(() => {
      this.riskDetail = this.riskScoreDetail.find(
        (detail) => detail.id === this.riskScore()
      );
    });
  }
}
