import { NgClass, NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';
import { PercentageCircleColour } from '@dx-web/modules/shared/types';

/**
 * @component PercentageCircleComponent
 * @description Displays a circular progress bar with a percentage in the center.
 * This component is used to visually represent a percentage value in a circular format,
 * with the option to display it in a loading state.
 *
 * @input label - Required. Specifies the color of the circle based on predefined color codes.
 * @input percentage - Required. The numerical value to display and calculate the circle's filled portion.
 * @input size - Optional. Defines the size of the circle. Defaults to '14'.
 * @input loading - Optional. Determines if the circle is in a loading state, altering its appearance.
 */
@Component({
  selector: 'dx-web-percentage-circle',
  standalone: true,
  imports: [NgStyle, NgClass],
  template: `
    <!-- Circle container with dynamic size and conditional class for loading animation -->
    <div
      class="relative"
      style="width: {{ size() }}px; height: {{ size() }}px"
      [ngClass]="{ 'animate-pulse': loading() }"
    >
      <!-- SVG circle representing the background and progress -->
      <svg
        class="block"
        style="width: {{ size() }}px; height: {{ size() }}px"
        viewBox="0 0 36 36"
      >
        <!-- Background circle path -->
        <path
          class="fill-none stroke-gray-200 stroke-[4]"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <!-- Foreground circle path with dynamic stroke-dasharray and color based on input -->
        <path
          class="animate-progress fill-none stroke-gray-500 stroke-[4]"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke-linecap="round"
          [attr.stroke-dasharray]="loading() ? '0' : percentage() + ', 100'"
          [ngStyle]="{ stroke: getStrokeColor() }"
        />
      </svg>

      <!-- Percentage text display -->
      <div
        class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center"
      >
        <p
          class="!m-0 pl-[2px] text-base font-medium"
          [ngClass]="{
            'h-2 w-1/3 rounded bg-slate-200 text-transparent': loading()
          }"
        >
          {{ percentage() }}%
        </p>
      </div>
    </div>
  `
})
export class PercentageCircleComponent {
  public label = input.required<keyof typeof PercentageCircleColour>();
  public percentage = input.required<number>();
  public size = input<string>('14');
  public loading = input<boolean>();
  protected circleColours: typeof PercentageCircleColour =
    PercentageCircleColour;

  /**
   * @method getStrokeColor
   * @description Determines the stroke color of the progress circle based on the component's current state.
   * If in loading state, returns a predefined loading color. Otherwise, it returns the color corresponding
   * to the `label` input or a default color if the label does not match any predefined colors.
   * @returns {string} The hex color code for the stroke.
   */
  protected getStrokeColor(): string {
    if (this.loading()) return '#e5e7eb';
    return this.circleColours[this.label()] || '#000000';
  }
}
