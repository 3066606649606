import { NgClass, NgIf } from '@angular/common';
import { Component, input } from '@angular/core';
import { PercentageCircleColour } from '@dx-web/modules/shared/types';
import { HlmCardModule } from '@spartan-ng/ui-card-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { PercentageCircleComponent } from './percentage-circle.component';

/**
 * @component StatCardComponent
 * @description Displays a statistical card with dynamic content including title, count, icon, and an optional percentage circle.
 *
 * @input title - The title of the stat card, required.
 * @input count - The numerical count to display, required.
 * @input iconName - The name of the icon to display, required.
 * @input percentage - The percentage value for the optional percentage circle.
 * @input percentageLabel - The label for the percentage circle color scheme.
 * @input loading - Flag to indicate if the percentage circle is in a loading state.
 * @input colorScheme - Object containing color scheme information for the card, required.
 */
@Component({
  selector: 'dx-web-stat-card',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    HlmIconComponent,
    HlmCardModule,
    PercentageCircleComponent
  ],
  template: `
    <section
      hlmCard
      class="relative overflow-hidden rounded-xl border border-border bg-background p-4 shadow-sm before:absolute before:end-0 before:top-0 before:h-full before:w-full before:bg-gradient-to-br before:via-transparent before:blur-xl dark:before:via-transparent sm:p-5"
      [ngClass]="getSectionClasses()"
    >
      <div class="relative z-10 flex justify-between">
        <div class="space-y-1">
          <div hlmCardHeader class="space-y-4 p-0">
            <span
              class="inline-flex h-8 w-8 items-center justify-center rounded-lg bg-background text-text shadow md:h-10 md:w-10"
            >
              <!-- Dynamic icon component with conditional color class -->
              <hlm-icon
                class="h-4 w-4 flex-shrink-0 md:h-5 md:w-5"
                [name]="iconName()"
                [ngClass]="colorScheme().iconColor"
              />
            </span>
            <h3 hlmCardTitle class="font-normal">{{ title() }}</h3>
          </div>
          <p hlmCardContent class="p-0 text-2xl font-semibold">{{ count() }}</p>
        </div>

        <!-- Conditional rendering of Percentage Circle based on input -->
        @if (showPercentageCircle()) {
          <dx-web-percentage-circle
            size="110"
            [label]="percentageLabel()!"
            [loading]="loading()"
            [percentage]="percentage()!"
          />
        }
      </div>
    </section>
  `
})
export class StatCardComponent {
  public title = input.required<string>();
  public count = input.required<number>();
  public iconName = input.required<string>();
  public percentage = input<number>();
  public percentageLabel = input<keyof typeof PercentageCircleColour>();
  public loading = input<boolean>();
  public colorScheme = input.required<{
    gradientFrom: string;
    darkGradientFrom: string;
    iconColor: string;
  }>();

  /**
   * @method getSectionClasses
   * @description Constructs a string of class names based on the component's colorScheme input.
   * This method is used for dynamic styling of the card's section element.
   * @returns {string} A space-separated string of class names.
   */
  protected getSectionClasses() {
    return `${this.colorScheme().gradientFrom} ${this.colorScheme().darkGradientFrom}`;
  }

  /**
   * @method showPercentageCircle
   * @description Determines whether the percentage circle should be displayed.
   * The circle is shown only if the percentage input is neither null nor undefined.
   * @returns {boolean} True if the percentage circle should be shown, false otherwise.
   */
  protected showPercentageCircle() {
    return this.percentage() !== null && this.percentage() !== undefined;
  }
}
