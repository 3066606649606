import {
  lucideArrowDownUp,
  lucideArrowRight,
  lucideBookOpen,
  lucideCalendarClock,
  lucideCheckCheck,
  lucideChevronDown,
  lucideChevronLeft,
  lucideChevronRight,
  lucideChevronsUpDown,
  lucideChevronUp,
  lucideColumns3,
  lucideCommand,
  lucideCreditCard,
  lucideDownloadCloud,
  lucideExpand,
  lucideEye,
  lucideEyeOff,
  lucideGanttChart,
  lucideListX,
  lucideLoader2,
  lucideLogOut,
  lucideMailCheck,
  lucideMinimize2,
  lucideMoon,
  lucidePackageOpen,
  lucidePen,
  lucidePlus,
  lucideSearch,
  lucideSettings,
  lucideSettings2,
  lucideShieldAlert,
  lucideShieldCheck,
  lucideSun,
  lucideUploadCloud,
  lucideUserRound,
  lucideUsers,
  lucideX
} from '@ng-icons/lucide';

// INFO: we use ng-icons for the whole app, any icon used needs to be imported here or they can be provided directly in the component where they are used. This icons const is imported into app.config.ts
// https://ng-icons.github.io/ng-icons/#/browse-icons
export const icons = {
  lucideMailCheck,
  lucideShieldCheck,
  lucideChevronRight,
  lucideChevronDown,
  lucideChevronLeft,
  lucideSun,
  lucideMoon,
  lucideSearch,
  lucideSettings,
  lucideUserRound,
  lucideCommand,
  lucidePlus,
  lucideX,
  lucideCreditCard,
  lucideUsers,
  lucideCalendarClock,
  lucideGanttChart,
  lucideSettings2,
  lucideColumns3,
  lucideArrowDownUp,
  lucideChevronUp,
  lucideChevronsUpDown,
  lucideLogOut,
  lucideExpand,
  lucideListX,
  lucideMinimize2,
  lucideLoader2,
  lucideDownloadCloud,
  lucideUploadCloud,
  lucideShieldAlert,
  lucideEyeOff,
  lucideEye,
  lucideCheckCheck,
  lucideBookOpen,
  lucidePackageOpen,
  lucidePen,
  lucideArrowRight
};
